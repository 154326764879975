import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { setUserData } from 'src/store/user/UserSlice';
import { ExcelModal } from 'src/components/button/InsertData';
import ExcelTable from '../../../components/table/ExcelTable';
import * as XLSX from 'xlsx';
import styled, { keyframes } from 'styled-components';
import Wallet from 'src/components/walletbtn/WalletComponent';
import { images } from '../../../assets/dashboard/images';
import ExcelModal2 from 'src/components/admin/ExcelModal2';
import TLCConnection from 'src/components/table/Tlcconnection';

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'tertiary' | 'delete';
  hasIcon?: boolean;
  isActive?: boolean; // active 상태 추가
}
const LoadingSpinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DashboardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 20px;
`;

const DashboardTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 15px 30px; /* 패딩을 추가해서 버튼을 더 넓게 */
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive }) => (isActive ? '#4c51cd' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#4c51cd' : '#0f0f0f')};
  font-size: 18px; /* 글씨 크기 키우기 */
  font-weight: 600; /* 글씨 굵기 추가 */
  transition: all 0.3s ease;

  &:hover {
    color: #4c51cd;
  }
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const RightButtons = styled.div`
  display: flex;
  gap: 10px;
`;
const Button = styled.button<ButtonProps & { variantType?: string }>`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${({ isActive }) => (isActive ? 'blue' : 'gray')};
  display: flex;
  align-items: center;
  gap: ${({ hasIcon }) => (hasIcon ? '10px' : '0')};
  transition: all 0.3s ease;

  background-color: ${({ variant, variantType }) =>
    variantType === 'delete'
      ? '#F4F4F4'
      : variant === 'primary'
      ? '#4c51cd'
      : variant === 'secondary'
      ? 'white'
      : variant === 'tertiary'
      ? '#e1e1e1'
      : '#fff'};

  color: ${({ isActive, variant, variantType }) =>
    isActive
      ? 'blue' // active 상태일 때 텍스트 색상 blue로 변경
      : variantType === 'delete'
      ? '#A8A8A8'
      : variant === 'primary'
      ? '#fff'
      : variant === 'secondary'
      ? 'black'
      : variant === 'tertiary'
      ? '#4c51cd'
      : '#4c51cd'};

  &:hover {
    background-color: ${({ variant, variantType }) =>
      variantType === 'delete'
        ? '#E0E0E0'
        : variant === 'primary'
        ? '#3b43a1'
        : variant === 'secondary'
        ? '#F4F4F4'
        : variant === 'tertiary'
        ? '#d4d4d4'
        : '#f8f8f8'};
    border-color: ${({ variant }) => (variant === 'primary' ? '#3b43a1' : 'black')};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

// 아이콘을 위한 스타일링
const Icon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;
const DeleteButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #dbdbdb; /* border 색상 추가 */
  background-color: #f4f4f4;
  color: #a8a8a8;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // 배경을 어두운 반투명 색으로 설정
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center; // 내용들을 수평 중앙 정렬
  gap: 16px; // 각 요소 간의 간격 설정
  max-width: 400px;
  width: 100%;
`;

const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalIcon = styled.img`
  width: 120px; // 아이콘 크기 조정
  height: 120px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

const HistoryButton = styled.button<{ variant: string }>`
  padding: 10px 20px;
  min-width: 150px;
  background-color: ${(props) => (props.variant === 'primary' ? '#007bff' : '#f8f9fa')};
  color: ${(props) => (props.variant === 'primary' ? 'white' : 'black')};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const CloseButton = styled.button<{ variant: string }>`
  padding: 10px 20px;
  min-width: 150px;
  background-color: ${(props) => (props.variant === 'secondary' ? '#f8f9fa' : '#dc3545')};
  color: ${(props) => (props.variant === 'secondary' ? 'black' : 'white')};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const InsertData: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('harvest');
  const [activeTab2, setActiveTab2] = useState('incoming');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [transactionHashURL, setTransactionHashURL] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // 트랜잭션 성공 여부
  const [isActive, setIsActive] = useState(false);
  const [isSaveButtonDisabled2, setIsSaveButtonDisabled2] = useState(true);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true); // 저장 버튼 상태

  useEffect(() => {
    if (user.email_addr === 'factory@futuresense.co.kr') {
      console.log(user.email_addr);
      setIsAdminModalOpen(true);
    }
  }, [user.email_addr]);

  useEffect(() => {
    const hasNonCompliant = selectedRows.some((index) => excelData[index]?.상태 === 'Non-compliant');

    const isDisabled =
      selectedRows.length === 0 || // 아무 것도 선택되지 않았을 때
      hasNonCompliant; // Non-compliant 상태가 포함되었을 때

    setIsSaveButtonDisabled(isDisabled);
  }, [selectedRows, excelData]);

  const handleUpdateRow = (index: number, updatedRow: any) => {
    setExcelData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedRow; // 해당 인덱스의 데이터를 업데이트
      return newData;
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsActive(!isActive); // 버튼 상태를 토글
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSuccess(false); // 성공 상태 초기화
    setTransactionHashURL(''); // 트랜잭션 URL 초기화
    setIsActive(false); // active 상태 초기화
    setIsAdminModalOpen(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      const mimeType = selectedFile.type;

      console.log('File MIME Type:', mimeType); // MIME 타입 출력
      console.log('File Extension:', fileExtension); // 확장자 출력

      // 1. MIME 타입 검사 및 확장자 검사
      if (mimeType === 'application/haansoftxlsx' || fileExtension === 'xlsx') {
        // 2. 파일 내용을 실제로 확인 (Excel 파일인지 파싱)
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const workbook = XLSX.read(reader.result, { type: 'binary' });
            // Excel 파일이 올바르게 파싱되었다면
            console.log('Excel file loaded:', workbook);
            setFile(selectedFile); // 파일 상태 업데이트
          } catch (error) {
            alert('Invalid Excel file');
          }
        };
        reader.readAsBinaryString(selectedFile);
      } else {
        alert('Please upload a valid Excel file');
      }
    }
  };
  const handleFileUpload2 = async () => {
    setIsAdminModalOpen(false);
    setIsLoading(true); // 로딩 시작
    try {
      const response = await fetch('/harvest_default.xlsx'); // public 폴더에서 엑셀 데이터 가져오기
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      setExcelData(jsonData); // 엑셀 데이터를 상태로 설정
      setIsSaveButtonDisabled2(false); // 저장 버튼 활성화
    } catch (error) {
      console.error('Error loading Excel file:', error);
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          setExcelData(jsonData);
        }
        setIsLoading(false);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleUpdateRows = (updates: { index: number; TLC: string }[]) => {
    setExcelData((prevData) => {
      const newData = [...prevData];
      updates.forEach(({ index, TLC }) => {
        if (newData[index]) {
          newData[index].TLC = TLC; // 해당 행의 TLC 값 업데이트
        }
      });
      return newData;
    });
  };

  const handleTabChange = (tab: string) => {
    setExcelData([]);
    setActiveTab(tab); // admin2 계정의 활성 탭 변경
  };
  const handleTabChange3 = (tab: string) => {
    setExcelData([]);
    setActiveTab(tab); // admin2 계정의 활성 탭 변경
    handleFileUpload2();
  };

  const handleTabChange2 = (tab: string) => {
    setExcelData([]);
    setActiveTab2(tab); // admin2 계정의 활성 탭 변경
  };

  const handleRowSelect = (index: number) => {
    setSelectedRows(
      (prev) =>
        prev.includes(index)
          ? prev.filter((id) => id !== index) // 이미 선택된 행이면 제거
          : [...prev, index] // 새로운 선택이면 추가
    );
  };
  const handleUpdateStatus = (updatedData: any[]) => {
    setExcelData(updatedData); // 상태값이 업데이트된 데이터를 저장
  };
  // const isSaveButtonDisabled = selectedRows.length === 0;

  const handleSave = async () => {
    const data = {
      siteOfNextRecipient: {
        businessName: 'Recipient Business',
        phoneNumber: '123-456-7890',
        region: 'Region',
        postalCode: '12345',
        country: 'Country',
        address: '123 Recipient St.',
        city: 'Recipient City',
      },
      siteOfHarvestedFarm: {
        businessName: 'Farm Business',
        phoneNumber: '098-765-4321',
        address: '123 Farm St.',
        city: 'Farm City',
        region: 'Farm Region',
        postalCode: '54321',
        country: 'Country',
      },
      commodityCode: 'CODE123',
      commodityName: 'Commodity Name',
      variety: 'Commodity Variety',
      quantity: 100.0,
      unitOfMeasure: 'kg',
      farmingArea: 'Area',
      harvestDate: '2024/10/30',
      refDocumentType: 'Document Type',
      refDocumentNumber: 'Document Number',
    };

    setIsLoading(true); // 로딩 시작
    try {
      const response = await fetch('https://futuresense.duckdns.org/v1/fda/ftr/harvesting', {
        method: 'POST',
        headers: {
          'x-api-key': '3VyGEwKFiq198oXl17Flp5a1A3seP1lq5BghjOmr',
          'Content-Type': 'application/json',
          Accept: '*/*',
          'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        setTransactionHashURL(result.transactionHashURL); // txHash URL 저장
        setIsSuccess(true); // 트랜잭션 성공 처리
      } else {
        console.error('Error:', response.status, await response.text());
      }
    } catch (error) {
      console.error('Request failed:', error);
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  // const handleSave = async () => {
  //   const data = {
  //     siteOfNextRecipient: {
  //       businessName: 'Recipient Business',
  //       phoneNumber: '123-456-7890',
  //       region: 'Region',
  //       postalCode: '12345',
  //       country: 'Country',
  //       address: '123 Recipient St.',
  //       city: 'Recipient City',
  //     },
  //     siteOfHarvestedFarm: {
  //       businessName: 'Farm Business',
  //       phoneNumber: '098-765-4321',
  //       address: '123 Farm St.',
  //       city: 'Farm City',
  //       region: 'Farm Region',
  //       postalCode: '54321',
  //       country: 'Country',
  //     },
  //     commodityCode: 'CODE123',
  //     commodityName: 'Commodity Name',
  //     variety: 'Commodity Variety',
  //     quantity: 100.0,
  //     unitOfMeasure: 'kg',
  //     farmingArea: 'Area',
  //     harvestDate: '2024/10/30',
  //     refDocumentType: 'Document Type',
  //     refDocumentNumber: 'Document Number',
  //   };

  //   setIsLoading(true); // 로딩 시작
  //   try {
  //     const response = await fetch('/v1/fda/ftr/harvesting', {
  //       method: 'POST',
  //       headers: {
  //         'x-api-key': '3VyGEwKFiq198oXl17Flp5a1A3seP1lq5BghjOmr',
  //         'Content-Type': 'application/json',
  //         Accept: '*/*',
  //         'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (response.ok) {
  //       const result = await response.json();
  //       console.log('Success:', result);
  //       setTransactionHashURL(result.transactionHashURL); // txHash URL 저장
  //       setIsSuccess(true); // 트랜잭션 성공 처리
  //     } else {
  //       console.error('Error:', response.status, await response.text());
  //     }
  //   } catch (error) {
  //     console.error('Request failed:', error);
  //   } finally {
  //     setIsLoading(false); // 로딩 종료
  //   }
  // };

  return user.email_addr === 'farmer@futuresense.co.kr' ? (
    <DashboardContainer>
      <DashboardHeader>
        <DashboardTitle></DashboardTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </DashboardHeader>
      <TabsContainer>
        <Tab isActive={activeTab === 'harvest'} onClick={() => handleTabChange('harvest')}>
          수확
        </Tab>
        <Tab isActive={activeTab === 'shpping'} onClick={() => handleTabChange3('shpping')}>
          출고
        </Tab>
      </TabsContainer>

      <TabContent>
        {activeTab === 'harvest' && (
          <>
            <ButtonsContainer>
              <LeftButtons>
                <DeleteButton>삭제</DeleteButton>
                <Button variant="secondary" hasIcon disabled>
                  <Icon src={images.plus} alt="삭제 아이콘" />
                  데이터 입력
                </Button>
                <Button onClick={openModal} variant="secondary" hasIcon isActive={isActive}>
                  <Icon src={isActive ? images.uploadActive : images.upload} />
                  양식 업로드
                </Button>
                <ExcelModal
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                  onFileChange={handleFileChange}
                  onFileUpload={handleFileUpload}
                  userEmail={user.email_addr} // userEmail 추가
                />
              </LeftButtons>
              <RightButtons>
                <DeleteButton>
                  <Icon src={images.download} alt="양식 업로드 아이콘" />
                  입력 데이터 다운로드
                </DeleteButton>
                <DeleteButton>임시 저장</DeleteButton>
                <Button variant="primary" disabled={isSaveButtonDisabled} onClick={handleSave}>
                  저장
                </Button>
              </RightButtons>
            </ButtonsContainer>

            {/* 로딩 모달 */}
            {isLoading && (
              <Modal>
                <ModalContent>
                  <ModalText>
                    {/* 로딩 스피너 */}
                    <LoadingSpinner />
                    <h2>Loading ..</h2>
                  </ModalText>
                </ModalContent>
              </Modal>
            )}

            {/* 트랜잭션 완료 후 모달 */}
            {!isLoading && isSuccess && (
              <Modal>
                <ModalContent>
                  <ModalIcon src={images.txsvg} alt="트랜잭션 완료 아이콘" />
                  <Title>이력이 저장되었습니다.</Title>
                  <ButtonsContainer>
                    <HistoryButton
                      variant="primary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        // 링크 열기
                        if (transactionHashURL) {
                          window.open(transactionHashURL, '_blank'); // 새 창으로 링크 열기
                        }

                        closeModal(); // 모달 닫기
                      }}
                    >
                      이력 확인하기
                    </HistoryButton>
                    <CloseButton
                      variant="secondary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        closeModal(); // 모달 닫기
                      }}
                    >
                      닫기
                    </CloseButton>
                  </ButtonsContainer>
                </ModalContent>
              </Modal>
            )}
            <ExcelTable
              data={excelData}
              onEdit={handleUpdateRow} // 부모 상태를 업데이트할 함수
              onRowSelect={handleRowSelect} // 선택된 행 정보를 업데이트
              selectAll={isSelectAll}
              handleSelectAll={() => {
                setIsSelectAll((prev) => !prev);
                setSelectedRows(!isSelectAll ? excelData.map((_, i) => i) : []); // 전체 선택/해제 처리
              }}
              onSaveEdit={(row) => console.log('저장된 데이터:', row)}
            />
          </>
        )}
        {activeTab === 'shpping' && (
          <>
            <ButtonsContainer>
              <LeftButtons>
                <DeleteButton>삭제</DeleteButton>
                <Button variant="secondary" hasIcon disabled>
                  <Icon src={images.plus} alt="삭제 아이콘" />
                  데이터 입력
                </Button>
                <Button onClick={openModal} variant="secondary" hasIcon isActive={isActive}>
                  <Icon src={isActive ? images.uploadActive : images.upload} />
                  양식 업로드
                </Button>
                <ExcelModal
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                  onFileChange={handleFileChange}
                  onFileUpload={handleFileUpload}
                  userEmail={user.email_addr} // userEmail 추가
                />
              </LeftButtons>
              <RightButtons>
                <DeleteButton>
                  <Icon src={images.download} alt="양식 업로드 아이콘" />
                  입력 데이터 다운로드
                </DeleteButton>
                <DeleteButton>임시 저장</DeleteButton>
                <Button variant="primary" disabled={isSaveButtonDisabled} onClick={handleSave}>
                  저장
                </Button>
              </RightButtons>
            </ButtonsContainer>

            {/* 로딩 모달 */}
            {isLoading && (
              <Modal>
                <ModalContent>
                  <ModalText>
                    {/* 로딩 스피너 */}
                    <LoadingSpinner />
                    <h2>Loading ..</h2>
                  </ModalText>
                </ModalContent>
              </Modal>
            )}

            {/* 트랜잭션 완료 후 모달 */}
            {!isLoading && isSuccess && (
              <Modal>
                <ModalContent>
                  <ModalIcon src={images.txsvg} alt="트랜잭션 완료 아이콘" />
                  <Title>이력이 저장되었습니다.</Title>
                  <ButtonsContainer>
                    <HistoryButton
                      variant="primary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        // 링크 열기
                        if (transactionHashURL) {
                          window.open(transactionHashURL, '_blank'); // 새 창으로 링크 열기
                        }

                        closeModal(); // 모달 닫기
                      }}
                    >
                      이력 확인하기
                    </HistoryButton>
                    <CloseButton
                      variant="secondary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        closeModal(); // 모달 닫기
                      }}
                    >
                      닫기
                    </CloseButton>
                  </ButtonsContainer>
                </ModalContent>
              </Modal>
            )}
            <ExcelTable
              data={excelData}
              onEdit={handleUpdateRow} // 부모 상태를 업데이트할 함수
              onRowSelect={handleRowSelect} // 선택된 행 정보를 업데이트
              selectAll={isSelectAll}
              handleSelectAll={() => {
                setIsSelectAll((prev) => !prev);
                setSelectedRows(!isSelectAll ? excelData.map((_, i) => i) : []); // 전체 선택/해제 처리
              }}
              onSaveEdit={(row) => console.log('저장된 데이터:', row)}
            />
          </>
        )}
      </TabContent>
    </DashboardContainer>
  ) : user.email_addr === 'factory@futuresense.co.kr' ? (
    <DashboardContainer>
      <DashboardHeader>
        <DashboardTitle></DashboardTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </DashboardHeader>
      <TabsContainer>
        <Tab isActive={activeTab2 === 'incoming'} onClick={() => handleTabChange2('incoming')}>
          입고
        </Tab>
        <Tab isActive={activeTab2 === 'processing'} onClick={() => handleTabChange2('processing')}>
          가공
        </Tab>
        <Tab isActive={activeTab2 === 'shipment'} onClick={() => handleTabChange2('shipment')}>
          출고
        </Tab>
      </TabsContainer>
      <TabContent>
        {activeTab2 === 'incoming' && (
          <>
            <ButtonsContainer>
              <LeftButtons>
                <DeleteButton>삭제</DeleteButton>
                <Button variant="secondary" hasIcon disabled>
                  <Icon src={images.plus} alt="삭제 아이콘" />
                  데이터 입력
                </Button>
                <Button onClick={openModal} variant="secondary" hasIcon isActive={isActive}>
                  <Icon src={isActive ? images.uploadActive : images.upload} />
                  양식 업로드
                </Button>
                <ExcelModal
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                  onFileChange={handleFileChange}
                  onFileUpload={handleFileUpload}
                  userEmail={user.email_addr} // userEmail 추가
                />
              </LeftButtons>
              <RightButtons>
                <DeleteButton>
                  <Icon src={images.download} alt="양식 업로드 아이콘" />
                  입력 데이터 다운로드
                </DeleteButton>
                <DeleteButton>임시 저장</DeleteButton>
                <Button variant="primary" disabled={isSaveButtonDisabled} onClick={handleSave}>
                  저장
                </Button>
              </RightButtons>
            </ButtonsContainer>
            {/* 로딩 모달 */}
            {isLoading && (
              <Modal>
                <ModalContent>
                  <ModalText>
                    <LoadingSpinner />
                    <h2>Loading...</h2>
                  </ModalText>
                </ModalContent>
              </Modal>
            )}
            {/* 트랜잭션 완료 모달 */}
            {!isLoading && isSuccess && (
              <Modal>
                <ModalContent>
                  <ModalIcon src={images.txsvg} alt="트랜잭션 완료 아이콘" />
                  <Title>이력이 저장되었습니다.</Title>
                  <ButtonsContainer>
                    <HistoryButton
                      variant="primary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        // 링크 열기
                        if (transactionHashURL) {
                          window.open(transactionHashURL, '_blank'); // 새 창으로 링크 열기
                        }

                        closeModal(); // 모달 닫기
                      }}
                    >
                      이력 확인하기
                    </HistoryButton>
                    <CloseButton
                      variant="secondary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        closeModal(); // 모달 닫기
                      }}
                    >
                      닫기
                    </CloseButton>
                  </ButtonsContainer>
                </ModalContent>
              </Modal>
            )}
            <ExcelTable
              data={excelData}
              onEdit={handleUpdateRow} // 부모 상태를 업데이트할 함수
              onRowSelect={handleRowSelect} // 선택된 행 정보를 업데이트
              selectAll={isSelectAll}
              handleSelectAll={() => {
                setIsSelectAll((prev) => !prev);
                setSelectedRows(!isSelectAll ? excelData.map((_, i) => i) : []); // 전체 선택/해제 처리
              }}
              onSaveEdit={(row) => console.log('저장된 데이터:', row)}
            />
          </>
        )}
        {activeTab2 === 'processing' && (
          <>
            <ButtonsContainer>
              <LeftButtons>
                <DeleteButton>삭제</DeleteButton>
                <Button variant="secondary" hasIcon disabled>
                  <Icon src={images.plus} alt="삭제 아이콘" />
                  데이터 입력
                </Button>
                <Button onClick={openModal} variant="secondary" hasIcon isActive={isActive}>
                  <Icon src={isActive ? images.uploadActive : images.upload} />
                  양식 업로드
                </Button>
                <ExcelModal
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                  onFileChange={handleFileChange}
                  onFileUpload={handleFileUpload}
                  userEmail={user.email_addr}
                />
              </LeftButtons>
              <RightButtons>
                <DeleteButton>
                  <Icon src={images.download} alt="양식 업로드 아이콘" />
                  입력 데이터 다운로드
                </DeleteButton>
                <DeleteButton>임시 저장</DeleteButton>
                <Button variant="primary" disabled={isSaveButtonDisabled} onClick={handleSave}>
                  저장
                </Button>
              </RightButtons>
            </ButtonsContainer>
            {isLoading && (
              <Modal>
                <ModalContent>
                  <ModalText>
                    <LoadingSpinner />
                    <h2>Loading...</h2>
                  </ModalText>
                </ModalContent>
              </Modal>
            )}
            {/* 트랜잭션 완료 모달 */}
            {!isLoading && isSuccess && (
              <Modal>
                <ModalContent>
                  <ModalIcon src={images.txsvg} alt="트랜잭션 완료 아이콘" />
                  <Title>이력이 저장되었습니다.</Title>
                  <ButtonsContainer>
                    <HistoryButton
                      variant="primary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        // 링크 열기
                        if (transactionHashURL) {
                          window.open(transactionHashURL, '_blank'); // 새 창으로 링크 열기
                        }

                        closeModal(); // 모달 닫기
                      }}
                    >
                      이력 확인하기
                    </HistoryButton>
                    <CloseButton
                      variant="secondary"
                      onClick={() => {
                        // 선택된 행 삭제
                        const updatedExcelData = excelData.filter((_, index) => !selectedRows.includes(index));
                        setExcelData(updatedExcelData); // 데이터 상태 업데이트
                        setSelectedRows([]); // 선택 상태 초기화
                        setIsSelectAll(false);
                        closeModal(); // 모달 닫기
                      }}
                    >
                      닫기
                    </CloseButton>
                  </ButtonsContainer>
                </ModalContent>
              </Modal>
            )}
            <ExcelTable
              data={excelData}
              onEdit={handleUpdateRow}
              onRowSelect={handleRowSelect}
              selectAll={isSelectAll}
              handleSelectAll={() => {
                setIsSelectAll((prev) => !prev);
                setSelectedRows(!isSelectAll ? excelData.map((_, i) => i) : []);
              }}
              onSaveEdit={(row) => console.log('저장된 데이터:', row)}
            />

            {/* 선택된 행에 대한 원부재료 목록 표시 */}
            <TLCConnection
              excelData={excelData}
              selectedRows={selectedRows}
              onUpdateRows={handleUpdateRows}
              onUpdateStatus={handleUpdateStatus}
            />
          </>
        )}
      </TabContent>
      {isAdminModalOpen && (
        <ExcelModal2 isOpen={isAdminModalOpen} closeModal={closeModal} onConfirm={handleFileUpload2} />
      )}
    </DashboardContainer>
  ) : null;
};

export default InsertData;
